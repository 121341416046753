import { Actions } from "redux/constants/Customer360"

const initialState = {
    customerData:{records: []}
}

export const customerDataReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_CUSTOMER_DATA:
          return {...state,customerData:payload};
          default:
              return state;
  }

}