import { Actions } from "redux/constants/Tags";

const initialState = {

}

export const tagReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_TAGS:
          return {...state,tags:payload};
          default:
              return state;
  }

}