import { Actions } from "redux/constants/AccountView"

const initialState = {
    accountView:[]
}

export const accountViewReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_ACCOUNTVIEW:
          return {...state,accountView:payload};
          default:
              return state;
  }

}