import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import ConfigProvider from 'antd/es/config-provider';
import enGB from 'antd/es/locale/en_GB'
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb'
import {THEME_CONFIG} from './configs/AppConfig'

moment.locale(THEME_CONFIG?.locale);



ReactDOM.render(
<ConfigProvider locale={enGB}>
    <App />
  </ConfigProvider>,
 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
