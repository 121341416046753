import { Actions } from "redux/constants/MenuManagement"

const initialState = {
    meunList:[]
}

export const menuListReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_MENULIST:
          return {...state,menuList:payload};
          default:
              return state;
  }

}