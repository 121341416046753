import { Actions } from "../constants/CustomerAccounts"

const initialState = {
    customerAccounts:[]
}

export const customerAccountsReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_CUSTOMER_ACCOUNTS:
          return {...state,customerAccounts:payload};
          default:
              return state;
  }

}