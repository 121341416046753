import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { getGridMenu } from 'redux/actions/Menu'
import { useDispatch, useSelector } from 'react-redux';

export const AppViews = () => {
  const dispatch = useDispatch();
	const {menu} = useSelector((state) => state.menu);


  useEffect(() => {
    if(!menu.length){
      dispatch(getGridMenu({}))
    }
	}, [])

  return (
    <>
    {menu.length === 0 ? (
      <Loading cover="content"/>  
    ):(
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
      <Route  exact path={`${APP_PREFIX_PATH}/workflows/account-review/:customerId/:accountId`} component={lazy(() => import(`./CustomerAccount`))} />
      <Route  exact path={`${APP_PREFIX_PATH}/customer360/:customerId`} component={lazy(() => import(`./CustomerDetails`))} />
      <Route  exact path={`${APP_PREFIX_PATH}/workflows/account_type/:name/:accountTypeId`} component={lazy(() => import(`./AccountDetails`))} />
      <Route   path={`${APP_PREFIX_PATH}/customer360/:customerId/:accountId`} component={lazy(() => import(`./CustomerAccount`))} />
        {menu.map((menuItem) => {
          const { name, path, children } = menuItem;
          const component = lazy(() => import(`./${name.split(" ").join("")}`));
          return (
            <Route key={path} path={path} component={component}>
              {children.map((child) => {
                const { name, path } = child;
                
                return(
                  <Route key={path} path={path} component={lazy(() => import(`./${name.split(" ").join("")}`))} />
                )
              })}
            </Route>
          ); 
        })}
        <Route exact path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./Customer360`))} />
        <Route exact path={`${APP_PREFIX_PATH}/customer360`} component={lazy(() => import(`./Customer360`))} />
        <Route path={`${APP_PREFIX_PATH}/page-not-found`} component={lazy(() => import(`./page404`))} />
        <Route path="*" >
          <Redirect to={`${APP_PREFIX_PATH}/page-not-found` }/>
        </Route>
      </Switch>
    </Suspense>
    
    )} 
    </>
  )
}

export default React.memo(AppViews);