import { Actions } from "redux/constants/TransactionGroups"

const initialState = {
    transactionGroups:[]
}

export const transactionGroupsReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_TRANSACTIONGROUPS:
          return {...state,transactionGroups:payload};
          default:
              return state;
  }

}