import { Actions } from "redux/constants/UserTypes"

const initialState = {
    userTypes:[]
}

export const userTypesReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_USERTYPES:
          return {...state,userTypes:payload};
          default:
              return state;
  }

}