import { Actions } from "redux/constants/Bin"

const initialState = {
    bin:[]
}

export const binReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_BIN:
          return {...state,bin:payload};
          default:
              return state;
  }

}