import React, {useEffect, useState} from "react";
import { Menu, Dropdown } from "antd";
import { connect } from 'react-redux'
import {  
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';
import { useKeycloak } from '@react-keycloak/web';

// const menuItem = [
// 	{
// 		title: "Edit Profile",
// 		icon: EditOutlined ,
// 		path: "/"
//     },
    
//     {
// 		title: "Account Setting",
// 		icon: SettingOutlined,
// 		path: "/"
//     },
//     {
// 		title: "Billing",
// 		icon: ShopOutlined ,
// 		path: "/"
// 	},
//     {
// 		title: "Help Center",
// 		icon: QuestionCircleOutlined,
// 		path: "/"
// 	}
// ]

export const NavProfile = ({signOut}) => {

  const [profile, setProfile] = useState({})
  const [keycloak] = useKeycloak();

  useEffect(() => {
    keycloak.loadUserInfo().then((user) => setProfile(user))
  }, [])

  useEffect(() => {
    if (profile?.default_country_iso !== undefined) {
      localStorage.setItem("defaultCountryCode", profile.default_country_iso);
    }
  }, [profile?.default_country_iso]);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
    keycloak.logout()
  }

  //const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">{profile.name}</h4>
            {/* <span className="text-muted">Frontend Developer</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* <Menu.Item  onClick={() => {keycloak.logout()}}> */}
          <Menu.Item  onClick={logout}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <UserOutlined className="mr-3" />
          {/* <Avatar src={profileImg} /> */}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
