import * as Request from '../utils/Request'




export const getCountriesList = async (body) => {
  try {
    const url ='/'
    body.url=`/api/rest/admin/list-countries`
    const headers = Request.authHeader()
    const res = await Request.POST(url, body, headers)
    return res
  } catch (error) {
    return error.response
  }
}

export const updateCountry = async (body) => {
    try {
     
      const url ='/'
      body.url= `/api/rest/admin/update-country`;
      const headers = Request.authHeader();
      const res = await Request.POST(url, body, headers);
      return res;
    } catch (error) {
      return error.response;
    }
  };

  export const deleteCountry = async (body) => {
    try {
     
      const url ='/'
      body.url= `/api/rest/admin/delete-country`;
      const headers = Request.authHeader();
      const res = await Request.POST(url, body, headers);
      return res;
    } catch (error) {
      return error.response;
    }
  };

  export const addCountry = async (body) => {
    try {
     
      const url ='/'
      body.url= `/api/rest/admin/add-country`;
      const headers = Request.authHeader();
      const res = await Request.POST(url, body, headers);
      return res;
    } catch (error) {
      return error.response;
    }
  };