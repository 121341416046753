import { Actions } from "redux/constants/Charges";

const initialState = {
    chargeModel:[]
}

export const chargeModelReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_CHARGE_MODEL:
          return {...state,chargeModel:payload};
          default:
              return state;
  }

}

const initialItem = {
    chargeModelItem:[]
}

export const chargeModelItemReducer = (state=initialItem,{type,payload}) =>{
  switch (type) {
      case Actions.SET_CHARGE_MODEL_ITEM:
          return {...state,chargeModelItem:payload};
          default:
              return state;
  }

}

const initialGroup = {
  transactionGroup:[]
}

export const transactionGroupReducer = (state=initialGroup,{type,payload}) =>{
  switch (type) {
      case Actions.SET_TRANSACTION_GROUP:
          return {...state,transactionGroup:payload};
          default:
              return state;
  }

}