import { Actions,Action } from "redux/constants/CustomerDetails"

const initialState = {
    details:[]
}

const initialCardState ={
    cardDetails:[]
}

export const detailsReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_CUSTOMER_DETAILS:
          return {...state,details:payload};
          default:
              return state;
  }

}

export const cardDetailsReducer = (state=initialCardState,{type,payload}) =>{
    switch (type) {
        case Action.SET_CARD_DETAILS:
            return {...state,cardDetails:payload};
            default:
                return state;
    }
  
  }