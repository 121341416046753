import { Actions } from "redux/constants/Rail"

const initialState = {
    rail:[]
}

export const railReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_RAIL:
          return {...state,rail:payload};
          default:
              return state;
  }

}