import { Actions } from "redux/constants/Templates"

const initialState = {
    template:[]
}

export const templateReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_TEMPLATE:
          return {...state,template:payload};
          default:
              return state;
  }

}