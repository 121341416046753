import {
  GET_MENU,
} from '../constants/Menu';
import { getMenu } from 'api/menu'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {DashboardOutlined,TeamOutlined,FileTextOutlined,
  FileDoneOutlined,BankOutlined,
  CreditCardOutlined,DollarCircleOutlined,
  CalendarOutlined,LinkOutlined,BarsOutlined,GlobalOutlined,FolderOpenOutlined, SwitcherOutlined,
  TransactionOutlined,UsergroupAddOutlined,AuditOutlined,UserOutlined,BorderTopOutlined,BulbOutlined,BranchesOutlined,FormOutlined,FundOutlined,MessageOutlined,VerticalAlignMiddleOutlined,
  TagsOutlined
} from '@ant-design/icons';



const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    payload: statusInfo
  }
}
const map1 = new Map([
  ["Dashboard", DashboardOutlined],
  ["Customer360", TeamOutlined],
  ["Lien View", FileTextOutlined],
  ["Approvals", FileDoneOutlined],
  ["Pay Points", BankOutlined],
  ["Rails",CreditCardOutlined],
  ["Templates",FileTextOutlined],
  ["Set Up Charges",DollarCircleOutlined],
  ["EOP Summaries",CalendarOutlined],
  ["Transactional Limits",BarsOutlined],
  ["Linked Account Providers",LinkOutlined],
  ["Countries",GlobalOutlined],
  ["PayPoint Categories",FolderOpenOutlined],
  ["Account Management",SwitcherOutlined],
  ["Transactions",TransactionOutlined],
  ["Transaction Groups",UsergroupAddOutlined],
  ["Account type limits",AuditOutlined],
  ["Transfer Options",BorderTopOutlined],
  ["User Types",UserOutlined],
  ["Workflow",BulbOutlined],
  ["Platform Management",BranchesOutlined],
  ["Tag Management",TagsOutlined ],
  ["BIN Management",BankOutlined],
  ["Notes",FormOutlined],
  ["Account Review",FundOutlined],
  ["Menu Management",BarsOutlined],
  ["Messaging",MessageOutlined],
  ["KYC Messaging",VerticalAlignMiddleOutlined]
  
  
]);

export const getGridMenu = (body) => {
  return async (dispatch) => {
    const result = await getMenu(body)
    if(result?.data?.success){
      const activeMenus = result.data.result.filter(item => item.status === 'ACTIVE');

      let newMenu = activeMenus.map(item => {
        const icons = map1.get(item.name)
        return(
          {
            key: item.id,
            path: `${APP_PREFIX_PATH}/${item.url}`,
            title: `sidenav.menu.${item.url}`,
            name: item.name,
            icon: icons,
            position: item.position,
            breadcrump: false,
            submenu: [],
            children:item.children.map(item=>{
              const icons = map1.get(item.name)
              return ( 
              {
                key: item.id,
                path: `${APP_PREFIX_PATH}/${item.url}`,
                title: `sidenav.menu.${item.url}`,
                name: item.name,
                position: item.position,
                icon: icons,
              })
            }
            )  
          }
        )
      })
      dispatch({ type: GET_MENU, payload: newMenu });
    }
    else{
      dispatch({ type: GET_MENU, payload: [] });
    }
  }
  
  
}