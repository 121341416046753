import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { KeycloakProvider } from '@react-keycloak/web'
import ErrorBoundary from 'components/layout-components/Errorboundry/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PermissionProvider } from "react-permission-role";
import Keycloak from 'keycloak-js'



const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};


const getTenantFromUrl = () => {
  const path = window.location.pathname;
  // Extract the first part after the domain
  const tenant = path.split('/')[1]; 
  return tenant;
};

const tenant = getTenantFromUrl();

function App() {
  const realm =tenant? tenant: window._env_.REACT_APP_REALM

localStorage.setItem('partnerId',realm )
  const keycloakConfig = { 
    url: window._env_.REACT_APP_URL,
    realm: realm ,
    clientId: window._env_.REACT_APP_CLIENT
  }

  const keycloak = new Keycloak(keycloakConfig);
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const idToken = localStorage.getItem('idToken');
    const keycloakTokens = {token, refreshToken, idToken}
    
  const setTokens = (token, idToken, refreshToken) => {
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      localStorage.setItem('idToken', JSON.stringify(idToken));
  }
 
  return (
    <div className="App">
      <PermissionProvider>
      <KeycloakProvider
        keycloak={keycloak} 
        onTokens={(tokens) => 
          setTokens(tokens.token ?? '', tokens.idToken ?? '', tokens.refreshToken ?? '')} 
        initOptions={{ onLoad: 'login-required', token, refreshToken }}
      >
        <Provider store={store}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
            <Router>
              <ErrorBoundary>
                <ToastContainer autoClose={2000} />
                <Switch>
                  <Route path="/" component={Views}/>
                </Switch>
              </ErrorBoundary>
            </Router>
          </ThemeSwitcherProvider>
        </Provider>
      </KeycloakProvider>
      </PermissionProvider>
    </div>
  );
}

export default App;
