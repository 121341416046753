import { Actions } from "redux/constants/UserNotes"

const initialState = {
    
}

export const userNotesReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_USER_NOTES:
          return {...state,userNotes:payload};
          default:
              return state;
  }

}