import { Actions,Action } from "redux/constants/Accounts"

const initialState = {
    accountType:[]
}
const initialLimitState ={
    accountTypeLimit:[]
}

export const accountTypeReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_ACCOUNTTYPE:
          return {...state,accountType:payload};
          default:
              return state;
  }

}

export const accountTypeLimitReducer = (state=initialLimitState,{type,payload}) =>{
    switch (type) {
        case Action.SET_ACCOUNT_TYPE_LIMIT:
            return {...state, accountTypeLimit:payload};
            default:
                return state;
    }
  
  }