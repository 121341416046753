import * as Request from 'utils/Request'




export const getMenu = async (body) => {
  try {
    const url ='/'
    body.url=`/api/rest/user/menu`
    const headers = Request.authHeader()
    const res = await Request.POST(url, body, headers)
    return res
  } catch (error) {
    return error.response
  }
}
export const addMenu = async (body) => {
  try {
    const menuUrl ='/menu'
    body.menuUrl=`/api/rest/admin/menu-save`
    const headers = Request.authHeader()
    const res = await Request.POST(menuUrl, body, headers)
    return res
  } catch (error) {
    return error.response
  }
}
export const editMenu = async (body) => {
  try {
    const menuUrl = "/menu"; 
    body.menuUrl ='/api/rest/admin/menu-edit';
    const headers = Request.authHeader();
    const res = await Request.POST(menuUrl, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};

export const deleteMenu = async (body) => {
  try {
    const menuUrl = "/menu"; 
    body.menuUrl ='/api/rest/admin/menu-delete';
    const headers = Request.authHeader();
    const res = await Request.POST(menuUrl, body, headers);
    return res;
  } catch (error) {
    return error.response;
  }
};
