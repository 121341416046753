import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Menu from './Menu'
import { paypointReducer } from './Paypoint';
import {categoryReducer} from './Categories'
import { templateReducer } from './Templates';
import { chargeModelReducer, chargeModelItemReducer,transactionGroupReducer} from './Charges';
import { kyclimitReducer } from './KycLimits';
import { countryReducer } from './Country';
import { detailsReducer ,cardDetailsReducer} from './CustomerDetails';
import { customerAccountsReducer } from './CustomerAccounts';
import { linkedAccountsReducer } from './LinkedAccounts';
import { accountTypeReducer,accountTypeLimitReducer } from './Accounts';
import { transactionGroupsReducer } from './TransactionGroups';
import { userTypesReducer } from './UserTypes';
import { transferOptionsReducer } from './TransferOptions';
import { customerDataReducer  } from './Customer360';
import {tagReducer} from './Tags'
import { userNotesReducer } from './UserNotes';
import { accountViewReducer } from './AccountView';
import { menuListReducer } from './MenuManagement';
import { binReducer } from './Bin';
import { railReducer } from './Rail';



const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    menu: Menu,
    paypoint:paypointReducer,
    category:categoryReducer,
    template:templateReducer,
    chargeModel:chargeModelReducer,
    chargeModelItem:chargeModelItemReducer,
    transactionGroup:transactionGroupReducer,
    kyclimit:kyclimitReducer,
    country:countryReducer,
    details:detailsReducer,
    cardDetails:cardDetailsReducer,
    customerAccounts:customerAccountsReducer,
    linkedAccounts:linkedAccountsReducer,
    accountType:accountTypeReducer,
    transactionGroups:transactionGroupsReducer,
    userTypes:userTypesReducer,
    accountTypeLimit:accountTypeLimitReducer,
    transferOptions:transferOptionsReducer,
    accountView:accountViewReducer,
    menuList:menuListReducer,
    customerData:customerDataReducer,
    tags:tagReducer,
    userNotes:userNotesReducer,
    bin:binReducer,
    rail:railReducer
});

export default reducers;