import React, { useEffect, useState } from "react";
import { Menu, Dropdown,Spin } from "antd";
import { CheckOutlined , DownOutlined} from "@ant-design/icons";
import { getCountriesList } from "api/country";

const CountryCodeSelector = () => {


const [country,setCountry]=useState()
const [flag,setFlag]=useState()

  useEffect(()=>{
    const getCountries =async()=>{
     
        const response = await getCountriesList(
            {
                "requestId": "{{$guid}}"
            }
        )
        setCountry(response?.data?.result)
      

        let selectedCountryCode = localStorage.getItem("selectedCountryCode");
        if (!selectedCountryCode) {
          selectedCountryCode = defaultCountryCode;
          localStorage.setItem("selectedCountryCode", selectedCountryCode);
        }
        const selectedOption = response?.data?.result?.find(
          (option) => option?.countryIso === selectedCountryCode
        );
        setSelectedCountry(selectedOption?.name);
        setFlag(selectedOption?.flag)
       

    }
getCountries()
},[])


  const [selectedCountry, setSelectedCountry] = useState("");
  const defaultCountryCode =localStorage.getItem('defaultCountryCode')

  const handleSelect = (value, label) => {
    
    setSelectedCountry(label);
    localStorage.setItem("selectedCountryCode", value);
    window.location.reload();
  };

  useEffect(() => {
   
  }, [selectedCountry]);

  const menu = (
    <Menu onClick={(e) => handleSelect(e.key, e.item.props.children)}>
      {country?.map((option) => (
        <Menu.Item key={option?.countryIso}>
          <span className="d-flex justify-content-between align-items-center">
          <div>
								<img style={{maxWidth: '20px'}} src={option?.flag} alt={option?.name}/>
								<span className="font-weight-normal ml-2">{option?.name}</span>
							</div>
            
            {selectedCountry === option?.name ? (
              <CheckOutlined className="text-success" />
            ) : null}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
    
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
      <div className="d-flex align-items-center">
        <img style={{maxWidth: '20px'}} src={flag} alt={selectedCountry}/>
        <span className="font-weight-semibold text-gray">{selectedCountry || "Select Country Code"} <DownOutlined className="font-size-xs"/></span>
        </div>
      </a>
  
      
    </Dropdown>
  );
};

export default CountryCodeSelector;
