import React from 'react'
import { useLocation,Link } from 'react-router-dom'
import './BreadCrumb.scss'

const AppBreadcrumb = ({customerId,accountId}) => {
	const location= useLocation()
  return (
	<nav>
		<Link to="/customer360" 
		className={location.pathname === "/customer360" ? "breadcrumb-active" : "breadcrumb-not-active"}
		>
			Customer360
		</Link>
		<span className="breadcrumb-arrow">&gt;</span>
		<Link to={{pathname:`/customer360/${customerId}`}}
		className={location.pathname===`customer360/${customerId}` ? "breadcrumb-active" : "breadcrumb-not-active"}
		>
			Customer_Story
		</Link>
		<span className="breadcrumb-arrow">&gt;</span>
		<Link to={{pathname:`/customer360/${customerId}/${accountId}`}} 
		className={location.pathname === `/customer360/${customerId}/${accountId}` ? "breadcrumb-active" : "breadcrumb-not-active"}
		>
			Customer_Account
		</Link>
	</nav>
  )
}

export default AppBreadcrumb
