import { Actions } from "redux/constants/TransferOptions"

const initialState = {
    transferOptions:[]
}

export const transferOptionsReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_TRANSFEROPTIONS:
          return {...state,transferOptions:payload};
          default:
              return state;
  }

}