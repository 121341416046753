import { Actions } from "redux/constants/Country"

const initialState = {
    country:[]
}

export const countryReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_COUNTRY:
          return {...state,country:payload};
          default:
              return state;
  }

}