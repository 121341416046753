import { Actions } from "redux/constants/LinkedAccounts"

const initialState = {
    linkedAccounts:[]
}

export const linkedAccountsReducer = (state=initialState,{type,payload}) =>{
  switch (type) {
      case Actions.SET_LINKEDACCOUNTS:
          return {...state,linkedAccounts:payload};
          default:
              return state;
  }

}